import React from 'react'

import * as styles from '../../../styles/modelo.module.css'
import Layout from '../../../components/layout';
import BannerExample from '../../../components/carrousel';
import { Grid } from "@material-ui/core";
import { Helmet } from 'react-helmet';
import Mapa from '../../../components/map';
const images = [
    { img: "/IMG_3440.jpg", title: 'Modelo El Contemporáneo', rows: 3, cols: 6 },
    { img: "/IMG_3408.jpg", title: 'Modelo El Contemporáneo', rows: 3, cols: 6 },
    { img: "/IMG_3399.jpg", title: 'Modelo El Contemporáneo', rows: 3, cols: 6 },
]

export default function Contemporaneo() {
    return (
        <Layout>

            <div className={styles.modeloCont}>
                <Helmet>
                    <title>Aries Online - El Contemporaneo</title>
                </Helmet>
                <div className={styles.modeloMain}>
                    <div className={styles.mainImg} style={{ backgroundImage: `url('/38ContemporaneoExt.jpg')` }}></div>

                    <div className={styles.mainInfo}>
                    <h1 className={styles.titleModelo}>Modelo</h1>
                    <h1 className={styles.titleModeloName}>El Contemporáneo</h1>
                    <h2 className={styles.titlePriceModelo}>Desde 216,650 USD*</h2>

            <div className={styles.featuresCont}>
              <div className={styles.features1}>
               <div className= {`${styles.feature} ${styles.itemsModelsMobile}`}>
                  <img src="/bed.png" alt="cama" className= {styles.iconModelLeft} />
                  <p>Recámaras <br />2</p>
                </div>
                <div className= {`${styles.feature} ${styles.itemsModelsMobile}`}>
                  <img src="/sink.png" alt="baño" className= {styles.iconModelLeft}/>
                                    <p>Baños Completos <br />2</p>
                </div>
                <div className= {`${styles.feature} ${styles.itemsModelsMobile}`}>
                  <img src="/sink.png" alt="baño" className= {styles.iconModelLeft}/>
                  <p>Medios Baños <br />1</p>
                </div>
              </div>

              <div className={styles.features2}>
              <div className={`${styles.feature} ${styles.paddingColumnModel} ${styles.itemsModelsMobile} ${styles.containerItemAreaTotal } `}>
                  <img src="/square.png" alt="area" className= {styles.iconModelRight} />
                  <p className={ styles.paddingDescriptionModel }>Área Total <br />173 m²</p>
                </div>
                <div className={`${styles.feature} ${styles.paddingColumnModel} ${styles.itemsModelsMobile}`}>
                  <img src="/wide.png" alt="terreno" className= {styles.iconModelRight} />
                  <p className={ styles.paddingDescriptionModel }>Terreno <br />120 m²</p>
                </div>
              </div>
            </div>

            <div className={styles.tyc}>
              <p className={styles.warningTextDescriptionModel}>*Consulte términos y condiciones.</p>
              <p className={styles.warningTextDescriptionModel}>Precios en dólares estadounidenses (USD).</p>
              <p className={styles.warningTextDescriptionModel}>Aplican restricciones y cambios sin previo aviso.</p>
            </div>
          </div>
        </div>

        <div className={styles.modeloImgs}>
          {/* <img src="/IMG_3399.jpg" alt="interior" />
            <img src="/IMG_3408.jpg" alt="interior" />
            <img src="/IMG_3440.jpg" alt="interior" /> */}
                    <BannerExample images={images} galeryStyleCarrusel={true}></BannerExample>
                </div>
                <Grid className={styles.divIframe} item xs={12} sm={12} md={12} lg={12} style={{ margin: "auto" }}>
                    <iframe className={styles.iframe} allowFullScreen={true} src='https://my.matterport.com/show/?m=LLvfNGn5JAr'></iframe>
                </Grid>
                {/* <div className={styles.plantasCont}>
                    <div className={styles.planta}>
                        <img src="/m1p1.jpg" alt="planta" />
                        <p>Planta Baja</p>
                    </div>
                    <div className={styles.planta}>
                        <img src="/m1p2.jpg" alt="planta" />
                        <p>Planta Alta</p>
                    </div>
                    <div className={styles.planta}>
                        <img src="/m1p3.png" alt="planta" />
                        <p>Terraza</p>
                    </div>
                </div> */}
                <Grid className={styles.divIframe} item xs={12} sm={12} md={12} lg={12} style={{ margin: "auto" }}>
                    <Mapa></Mapa>
                </Grid>
            </div>
        </Layout>
    )
}
